.wrap {
    composes: clearfix from global;
    width: 300px;
    float: left;
}

.box {
    width: 300px;
    height: 250px;
    margin-top: 20px;
}
