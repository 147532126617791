.wrap {
    composes: fl from global;
    width: 300px;
    padding-top: 18px;
}

.box {
    width: 300px;
    height: 183px;
}
