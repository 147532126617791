.wrap {
    composes: clearfix from global;
    width: 680px;
    min-height: 546px;
    float: left;
}

.list {
    width: 320px;
    float: left;
    margin-right: 20px;
    background: #fff;
    margin-bottom: 15px;
    & h3 {
        padding: 8px 17px 8px;

        & a {
            color: #1f2e45;
            font-size: 14px;
            font-family: Microsoft Yahei;
            display: block;
            line-height: 22px;
            height: 44px;
            overflow: hidden;
            display:-webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        & a:hover {
            color: #f54343;
        }
    }

    & p {
        line-height: 20px;
        font-size: 12px;
        color: #b0b0b0;
        padding: 0px 17px 11px;
        height: 40px;

        & span {
            display: inline-block;
            height: 40px;
            overflow: hidden;
        }
    }
}
