.col_box {
    width: 100%;
    margin: 0 auto;
    height: 40px;
    color: #2b2b2b;
}

.col_navlist {
    width: 1005px;
    margin: 0 auto;
    height: 39px;
    & ul {
        height: 39px;
        overflow: hidden;
        border-bottom: 1px solid #e1e1e1;
        & li {
            font-size: 14px;
            background: url(./child_nav.gif) right top no-repeat;
            height: 39px;
            line-height: 39px;
            padding: 0 14px 0 16px;
            float: left;
        }
        & li:first-child {
            padding-left: 0;
        }
        & a:hover {
            color: #3eb9e5;
            text-decoration: none;
            cursor: pointer;
        }
    }
}
