.wrap {
    composes: clearfix from global;
    width: 1000px;
    margin: 0 auto;
    margin-top: 30px;
}

.throughColumnAd2 {
    width: 1000px;
    height: 90px;
    margin-top: 30px;
}
