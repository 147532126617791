.cold_list {
    list-style: none;

    & li {
        line-height: 30px;
        background: url(./v_02.jpg) no-repeat left center;
        padding-left: 9px;
        height: 30px;
    }
}

.cold_a {
    font-size: 12px;
    color: #2b2b2b !important;
    display: block;
    height: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.cold_a:hover {
    color: #f54343 !important;
}
