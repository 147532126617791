.ul {
    float: left;
    padding-right: 30px;
    border-right: 1px solid #e3e3e3;
    margin-right: 18px;
    width: 282px;

    & li {
        line-height: 30px;
        background: url(./v_02.jpg) no-repeat left center;
        padding-left: 11px;
        height: 30px;
        width: 282px;
        overflow: hidden;

        & a {
            font-size: 14px;
            color: #2b2b2b;
            font-weight: bold;
        }
        & a:hover {
            color: #f54343;
        }
    }
}

.last {
    composes: ul;
    border-right: none;
    margin-right: 0;
}
