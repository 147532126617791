.wrap {
    composes: clearfix from global;
    margin-top: 40px;
    width: 1000px;
}

.businessNewAd1 {
    width: 1000px;
    height: 100%;
}

.throughColumnAd1 {
    width: 1000px;
    height: 100%;
    margin-bottom: 30px;
}
