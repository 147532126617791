.wrap {
    composes: clearfix from global;
    width: 680px;
}

.list {
    width: 210px;
    margin-right: 15px;
    float: left;
    background: #fff;
    margin: 0 15px 18px 0;

    & h3 {
        line-height: 24px;
        padding: 10px 15px 15px;
        height: 50px;
    }

    & h3 a {
        font-size: 14px;
        line-height:22px;
        font-family: Microsoft Yahei;
        color: #2b2b2b;
        font-weight: normal;
        display: block;
        height: 44px;
        overflow: hidden;
        display:-webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    & h3 a:hover {
        color: #f54343;
        text-decoration: underline;
    }

    & p {
        height: 55px;
        line-height: 20px;
        font-size: 12px;
        color: #acacac;
        padding: 0px 15px 34px;

        & span {
            display: inline-block;
            width: 180px;
            height: 58px;
            overflow: hidden;
        }
    }
}
