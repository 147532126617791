.wrap {
    composes: clearfix from global;
    width: 1025px;
}

.list {
    width: 320px;
    height: 183px;
    position: relative;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
}

.lv_pic {
    width: 115px;
    height: 115px;
    position: absolute;
    left: 13px;
    top: 60px;
}

.lv_word {
    position: absolute;
    left: 135px;
    bottom: 20px;
    width: 130px;

    & h3 {
        line-height: 20px;

        & a {
            font-size: 18px;
            color: #fff;
            font-family: Microsoft Yahei;
            font-weight: normal;
        }
    }

    & p {
        font-size: 12px;
        color: #b0b2b1;
        line-height: 16px;
    }
}

.lv_map {
    line-height: 16px;
    height: 16px;
    padding: 6px 0 6px 15px;
    color: #b0b2b1;
    background: url(./map.jpg) left center no-repeat;
}

.img {
    width: 320px;
    height: 183px;
}

.bg04 {
    composes: img;
    width: 320px;
    height: 183px;
    background: url(./v_37.jpg) no-repeat;
    position: absolute;
    left: 0;
    bottom: 0;
}
