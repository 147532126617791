.wrap {
    background: #fff;
    position: relative;
}

.fhlx {
    composes: clearfix from global;
    padding: 15px 17px 20px;
    border-bottom: 1px solid #e3e3e3;
}

.mr24 {
    composes: fl from global;
    margin-right: 24px;
}

.img {
    width: 109px;
    height: 73px;
}

.lx_word {
    composes: fl from global;
    width: 833px;
    height: 89px;
    overflow: hidden;
    & p {
        line-height: 18px;
        font-size: 12px;

        & span {
            color: #cecece;
        }
    }

    & h2 {
        line-height: 26px;
        padding-bottom: 13px;
        height: 26px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        & a {
            font-size: 24px;
            color: #2b2b2b;
            font-weight: bold;
            font-family: Microsoft Yahei;
            text-decoration: none;
        }

        & a:hover {
            text-decoration: none;
            color: #f54343;
        }
    }

    & span {
        display: inline-block;
        font-size: 12px;
        color: #2b2b2b;
        font-family: Microsoft Yahei;
        text-decoration: none;
        height: 16px;
        overflow: hidden;
    }
}
