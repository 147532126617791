.wrap {
    composes: clearfix from global;
    width: 1000px;
    margin: 0 auto;
    margin-top: 30px;
}

.box {
    composes: fr from global;
    width: 300px;
    height: 600px;
}

.box_0 {
    position: absolute;
    width: 170px;
    height: 30px;
    left: 0px;
    top: 2700px;
}

.box_1 {
    position: absolute;
    width: 170px;
    height: 30px;
    left: 0px;
    top: 2750px;
}
