.content {
    height: 100%;
    width: 100%;
    background: #f6f6f6;
}

.feet {
    margin-top: 60px;
}
.col_wbf {
    width: 100%;
    margin: 0 auto;
    background: #fff;
}
.col_w1000 {
    width: 1000px;
    margin: 0 auto;
}
.space01 {
    height: 26px;
    overflow: hidden;
}

.ewm_gs {
    position: fixed;
    right: 30px;
    bottom: 150px;
    z-index: 1000;
}

.box {
    width: 1000px;
    height: 90px;
    margin-top: 10px;
}

.fl {
    z-index: 9999998;
    position: fixed;
    float: left;
    display: block;
    top: 100px;
    left: 1px;
    width: 120px;
    height: 300px;
}

.fr {
    z-index: 9999998;
    position: fixed;
    float: right;
    display: block;
    top: 100px;
    right: 1px;
    width: 120px;
    height: 300px;
}

.float {
    z-index: 9999998;
    position: fixed;
    float: right;
    display: block;
    bottom: 295px;
    right: 1px;
}
