.wrap {
    composes: fl clearfix from global;
    width: 690px;
}

.list {
    width: 320px;
    float: left;
    background: #fff;
    padding-bottom: 15px;
    margin-right: 20px;
    margin-bottom: 20px;
    overflow: hidden;

    & p {
        line-height: 20px;
        padding: 10px 20px 17px;

        & a {
            display: block;
            height: 18px;
            overflow: hidden;
            font-size: 14px;
            color: #1f2f3f;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        & a:hover {
            color: #f54343;
        }
    }
}

.qqg {
    padding: 0px 20px;
    line-height: 16px;

    & a {
        background: url(./map.gif) no-repeat left center;
        padding-left: 16px;
        display: inline-block;
        height: 16px;
        overflow: hidden;
        color: #9a9a9a;
        font-size: 12px;
    }
}
