.col_box {
    position: relative;
    background: #3eb9e5;
    width: 100%;
    margin: 0 auto;
    height: 39px;
}

.col_navlist {
    width: 1005px;
    margin: 0 auto;
    height: 39px;
    & ul {
        background: url(./line05.jpg) left top no-repeat;
        height: 39px;
        overflow: hidden;
        & li {
            font-size: 14px;
            height: 39px;
            line-height: 39px;
            background: url(./line05.jpg) right top no-repeat;
            padding: 0 20px;
            float: left;
            & a {
                color: #fff;
            }
        }
        & a:hover {
            color: #bcdceb;
            text-decoration: none;
            cursor: pointer;
        }
    }
}
