.ActionLeague {
    width: 1000px;
    margin: 10px auto;
    & .slideItem {
        position: relative;
    }
    & [ifeng_ui_pc_sliders-arrows_pre] {
        width: 42px;
        height: 90px;
        background: rgba(0, 0, 0, 0.4) url(./bt_left.gif) no-repeat center center !important;
        position: absolute;
        top: 30% !important;
        margin-top: -10px !important;
        z-index: 100 !important;
    }
    & [ifeng_ui_pc_sliders-arrows_next] {
        width: 42px;
        height: 90px;
        background: rgba(0, 0, 0, 0.4) url(./bt_right.gif) no-repeat center center !important;
        position: absolute;
        top: 30% !important;
        margin-top: -10px !important;
        z-index: 100 !important;
    }
    & [ifeng_ui_pc_sliders-dots] {
        position: absolute;
        right: 21px !important;
        left: 933px !important;
        top: 438px !important;
        height: 4px;
    }
}
.text_wrap {
    width: 968px;
    height: 108px;
    padding: 0px 16px;
    background: #fff;

    & p {
        width: 810px;
        height: 40px;
        font-size: 12px;
        color: #999999;
        line-height: 20px;
        overflow: hidden;
    }
}

.title_wrap {
    line-height: 24px;
    padding: 25px 0px 8px;

    & a {
        font-size: 20px;
        font-family: Microsoft Yahei;
        font-weight: bold;
        color: #2b2b2b;
    }

    & a:hover {
        color: #f54343;
        text-decoration: none;
    }
}

[ifeng_ui_pc_sliders-dots] {
    position: absolute;
    /* left: 50% !important; */
    bottom: -40px !important;
    right: 20px !important;
    margin-left: -80px !important;
}
.dot {
    float: left;
    width: 20px !important;
    height: 4px !important;
    margin: 0 0 0 5px !important;
    display: block;
    cursor: pointer;
    background: #e3e3e3 !important;
    overflow: hidden;
}
.current {
    float: left;
    width: 20px !important;
    height: 4px !important;
    margin: 0 0 0 5px !important;
    display: block;
    cursor: pointer;
    background: #3eb9e5 !important;
    overflow: hidden;
}
