.ActionLeague {
    width: 1000px;
    margin: 10px auto;

    & [ifeng_ui_pc_sliders-arrows_pre] {
        width: 8px;
        height: 32px;
        position: absolute !important;
        left: -22px !important;
        top: 53px !important;
        cursor: pointer;
        background: url(./bt_left.jpg) no-repeat !important;
    }
    & [ifeng_ui_pc_sliders-arrows_next] {
        width: 8px;
        height: 32px;
        position: absolute !important;
        right: -22px !important;
        top: 53px !important;
        cursor: pointer;
        background: url(./bt_right.jpg) no-repeat !important;
    }
}

.slideItem {
    position: relative;
    float: left;
    margin-right: 20px;
}

.last {
    composes: slideItem;
    margin-right: 0;
}

.text_wrap {
    width: 185px;
    background: #fff;
    line-height: 24px;
    padding: 10px 35px 10px 15px;

    & a {
        display: block;
        height: 44px;
        font-size: 14px;
        line-height:22px;
        color: #1f2d47;
        overflow: hidden;
        display:-webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    & a:hover {
        color: #f54343;
    }
}

[ifeng_ui_pc_sliders-dots] {
    position: absolute;
    /* left: 50% !important; */
    bottom: -40px !important;
    right: 20px !important;
    margin-left: -80px !important;
}
