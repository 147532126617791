.travel_main {
    width: 1000px;
    margin: 0 auto;
}
.con_box {
    margin-top: 30px;
    composes: clearfix from global;
}
.lis_nopic {
    float: left;
    width: 650px;
    overflow: hidden;
}
