.wrap {
    composes: clearfix from global;
    width: 1000px;
    margin: 30px auto;
}

.box {
    width: 1000px;
    height: 90px;
    margin-bottom: 30px;
}
