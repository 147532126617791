.wrap {
    composes: clearfix from global;
    /* background: #FFF; */
    margin-bottom: 10px;
}

.list {
    background: #fff;
}

.pd15 {
    composes: clearfix from global;
    padding: 0px 15px;
}

.xs_word_02 {
    padding-right: 13px;
    line-height: 20px;
    padding: 13px 13px 6px 0;

    & a {
        display: block;
        height: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        color: #787878;
    }

    & a:hover {
        color: #f54343;
    }
}

.qqg {
    padding-bottom: 15px;
    line-height: 16px;

    & a {
        color: #9a9a9a;
        font-size: 12px;
        background: url(./map.gif) no-repeat left center;
        padding-left: 14px;
        display: inline-block;
        height: 16px;
        overflow: hidden;
    }
}
