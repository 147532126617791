.wrap {
    composes: clearfix from global;
    width: 100%;
    background: url(./v_10.jpg) repeat-x left bottom;
    position: relative;
    margin-bottom: 9px;

    & li {
        height: 49px;
        background: url(./v_05.jpg) repeat-x left top;
        float: left;
        line-height: 48px;
        font-size: 20px;
        font-family: Microsoft Yahei;
        color: #5c5c5c;
        font-weight: bold;
        text-align: center;
        cursor: pointer;
        margin-right: 1px;

        & a {
            display: block;
            padding: 0px 34px;
            height: 49px;
            color: #5c5c5c;
        }
        & a:hover {
            color: #5c5c5c;
            text-decoration: none;
        }
    }
}

.current {
    background: url(./v_06.jpg) repeat-x left top !important;
    color: #3cbae3 !important;
}
