.wrap {
    composes: clearfix from global;
    /* background: #FFF; */
    width: 1025px;
}

.list {
    float: left;
    margin-right: 20px;
    width: 235px;
    height: 130px;
    position: relative;
}

.black_01 {
    position: absolute;
    width: 235px;
    height: 70px;
    left: 0;
    bottom: 0;
    background: url(./v_27.png) no-repeat;
}

.word_01 {
    position: absolute;
    left: 15px;
    bottom: 15px;
    width:210px;

    & a {
        font-size: 14px;
        color: #fff;
        font-family: Microsoft Yahei;
        display: block;
        height: 21px;
        overflow: hidden;
        line-height: 21px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    & a:hover {
        color: #fff;
    }
}
